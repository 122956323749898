// Media queries can't be used as native CSS variables (yet)
$bp-mobile-s: 320px !default;
$bp-mobile-m: 375px !default;
$bp-mobile-l: 414px !default;
$bp-tablet-s: 562px !default;
$bp-tablet-m: 768px !default;
$bp-tablet-l: 920px !default;
$bp-desktop-1-xs: 1024px !default;
$bp-desktop-s: 1120px !default;
$bp-mobile-nav: 1240px !default;
$bp-desktop-m: 1280px !default;
$bp-desktop-l: 1440px !default;
$bp-desktop-1-xl: 1650px !default;
$bp-desktop-2-xl: 1920px !default;

%heading {
  font-weight: 600;
  color: var(--sweet-liquorice-900);
  line-height: var(--leading-tight);
  letter-spacing: var(--tracking-normal);
}

%paragraph {
  font-weight: 500;
  color: var(--sweet-liquorice-750);
  line-height: var(--leading-normal);
  letter-spacing: var(--tracking-normal);
}

%quote {
  font-weight: 500;
  color: var(--sweet-liquorice-900);
  line-height: var(--leading-normal);
  letter-spacing: var(--tracking-normal);
}

%link {
  font-weight: 500;
  text-decoration: underline;
  color: var(--sweet-liquorice-900);
  line-height: var(--leading-none);
  letter-spacing: var(--tracking-normal);
}

%button {
  font-weight: 600;
  line-height: var(--leading-normal);
  letter-spacing: var(--tracking-normal);
}

.inversed {
  %heading {
    color: var(--white-000);
  }

  %paragraph,
  %quote,
  %link {
    color: var(--sweet-liquorice-300);
  }
}

@mixin h1 {
  @extend %heading;
  font-size: var(--heading-6-xl);
}

@mixin h2 {
  @extend %heading;
  font-size: var(--heading-3-xl);
}

@mixin h3 {
  @extend %heading;
  font-size: var(--heading-2-xl);
}

@mixin h4 {
  @extend %heading;
  font-size: var(--heading-1-xl);
}

@mixin h5 {
  @extend %heading;
  font-size: var(--heading-l);
}

@mixin h6 {
  @extend %heading;
  font-size: var(--heading-m);
}

@mixin streamer {
  @extend %paragraph;
  font-size: var(--streamer-size);
  font-weight: 500;
}

@mixin quote-1-xl {
  @extend %quote;
  font-size: var(--heading-2-xl);
}

@mixin quote-l {
  @extend %quote;
  font-size: var(--heading-l);
}

@mixin body-s {
  @extend %paragraph;
  font-size: var(--body-s);
}

@mixin body-m {
  @extend %paragraph;
  font-size: var(--body-m);
}

@mixin body-l {
  @extend %paragraph;
  font-size: var(--body-l);
}

@mixin body-1-xl {
  @extend %paragraph;
  font-size: var(--body-1-xl);
}

@mixin body-2-xl {
  @extend %paragraph;
  font-size: var(--body-2-xl);
}

@mixin textlink-l {
  @extend %link;
  font-weight: 600;
}

@mixin textlink-m {
  @extend %link;
}

@mixin textlink-s {
  @extend %link;
}

@mixin caption {
  font-weight: 500;
}

@mixin eyebrow {
  text-transform: uppercase;
  font-weight: 600;
  font-size: var(--heading-s);
  color: var(--sweet-liquorice-500);
}

@mixin footerlink-m {
  color: var(--sweet-liquorice-100);
}

@mixin footerlink-s {
  color: var(--sweet-liquorice-100);
}

@mixin button-l {
  @extend %button;
  font-size: var(--body-l);
}

@mixin button-m {
  @extend %button;
  font-size: var(--body-m);
}

@mixin button-s {
  @extend %button;
  font-size: var(--body-s);
}

// DEFAULT

// Heavy
@font-face {
  font-family: 'Dazzed';
  src:
    url('/fonts/Dazzed-Heavy.woff2') format('woff2'),
    url('/fonts/Dazzed-Heavy.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: optional;
}

// Bold
@font-face {
  font-family: 'Dazzed';
  src:
    url('/fonts/Dazzed-Bold.woff2') format('woff2'),
    url('/fonts/Dazzed-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: optional;
}

// Semi-Bold
@font-face {
  font-family: 'Dazzed';
  src:
    url('/fonts/Dazzed-SemiBold.woff2') format('woff2'),
    url('/fonts/Dazzed-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: optional;
}

// Medium
@font-face {
  font-family: 'Dazzed';
  src:
    url('/fonts/Dazzed-Medium.woff2') format('woff2'),
    url('/fonts/Dazzed-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: optional;
}

// ITALIC

// Heavy Italic
@font-face {
  font-family: 'Dazzed';
  src:
    url('/fonts/Dazzed-HeavyItalic.woff2') format('woff2'),
    url('/fonts/Dazzed-HeavyItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: optional;
}

// Bold Italic
@font-face {
  font-family: 'Dazzed';
  src:
    url('/fonts/Dazzed-BoldItalic.woff2') format('woff2'),
    url('/fonts/Dazzed-BoldItalic.woff') format('woff');
  font-weight: 700;
  font-style: italic;
  font-display: optional;
}

// Semi-Bold Italic
@font-face {
  font-family: 'Dazzed';
  src:
    url('/fonts/Dazzed-SemiBoldItalic.woff2') format('woff2'),
    url('/fonts/Dazzed-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: optional;
}

// Medium Italic
@font-face {
  font-family: 'Dazzed';
  src:
    url('/fonts/Dazzed-MediumItalic.woff2') format('woff2'),
    url('/fonts/Dazzed-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: optional;
}

.overlay {
  background: rgba(var(--sweet-liquorice-900-rgb), 30%);
  height: 100%;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
}

.main-nav {
  --duration: 1s;
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 40px);
  max-width: 100%;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 112;
  pointer-events: none;

  a,
  button {
    pointer-events: all;
  }

  a {
    --accent: var(--fresh-mint-600);
  }

  .top-bar > div {
    margin: 0 auto;
    position: relative;
    z-index: 3;
  }

  .top-bar {
    align-items: center;
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    padding: var(--spacing-2-xl) 0;
    position: relative;
    z-index: 3;
    padding-right: var(--scrollbar-width);

    @media screen and (prefers-reduced-motion: no-preference) {
      transition: none .3s var(--ease-out);
      transition-property: transform, background-color;
    }
  }

  .bar-container {
    display: flex;
    justify-content: space-between;
  }

  .logo-mark {
    #fresh-mint-mark {
      display: none;
    }
  }

  .logo {
    align-items: center;
    display: flex;
    gap: 12px;
    height: 100%;
    overflow: hidden;
    opacity: 1;
    transition: none .2s var(--ease-out);
    transition-property: opacity;

    &.fade {
      opacity: 0;
    }

    img {
      height: 40px;
      max-height: 100%;
      max-width: 100%;
      object-fit: contain;
      vertical-align: middle;

      @media screen and (max-width: $bp-tablet-s) {
        height: 32px;
        width: auto;
      }
    }

    img + img {
      @media screen and (max-width: $bp-tablet-s) {
        height: 23px;
        width: 130px;
      }
    }
  }

  .cta {
    display: grid;
    gap: var(--ui-size-3-xs);
    grid-template-columns: auto auto;

    .start-button {
      z-index: 10;

      @media screen and (max-width: $bp-tablet-m) {
        display: none;
      }
    }

    .menu-button {
      z-index: 3;
      min-width: 130px;
      width: 135px;

      > span > span {
        width: 135px;
      }
    }

    @media screen and (max-width: $bp-tablet-m) {
      gap: 0;
    }
  }

  .menu {
    z-index: 2;
    position: fixed;
    width: calc(50vw + var(--scrollbar-width));
    top: 0;
    right: 0;

    // Fallback for browsers that don't support dvh
    height: 100vh;
    height: 100dvh;
    padding-right: var(--scrollbar-width);

    @media screen and (max-width: $bp-desktop-l) {
      width: calc(552px + var(--scrollbar-width));
    }

    @media screen and (max-width: $bp-tablet-s) {
      width: 100%;
    }

    @media screen and (min-width: $bp-desktop-1-xl) {
      width: calc(45vw + var(--scrollbar-width));
    }

    .menu-container {
      --offset-right: 0;

      padding-top: var(--offset-top);
      overflow: hidden;
      height: 100%;
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      align-items: baseline;
      position: relative;
      max-width: 720px;

      @media screen and (max-width: $bp-tablet-s) {
        --offset-top: 132px !important;
      }

      @media screen and (max-width: $bp-mobile-l) {
        grid-template-columns: repeat(8, 1fr);
      }

      @media screen and (max-width: $bp-mobile-m) {
        grid-template-columns: repeat(5, 1fr);
      }

      @media screen and (min-width: $bp-desktop-1-xl) {
        position: absolute;
        top: 0;
        right: var(--offset-right);
        width: calc(100% - var(--offset-right));
        max-width: unset;
      }

      .mark {
        position: absolute;
        top: 36px;
        left: 32px;

        @media screen and (max-width: $bp-tablet-s) {
          top: 26px;
          left: 24px;
        }
      }

      .start-button-in-menu {
        position: absolute;
        display: none;

        @media screen and (max-width: $bp-tablet-m) {
          top: 24px;
          right: 172px;
          display: block;
        }

        @media screen and (max-width: $bp-tablet-s) {
          display: none;
        }
      }

      .group {
        padding: var(--spacing-1-xl) var(--spacing-2-xl);

        @media screen and (max-width: $bp-tablet-s) {
          padding:
            var(--spacing-2-xl)
            var(--spacing-4-xl)
            var(--spacing-2-xl)
            0;
        }

        &.small {
          grid-column: 1 / 3;
          display: grid;
          justify-content: center;
          align-self: baseline;

          @media screen and (max-width: $bp-tablet-s) {
            justify-content: end;
          }

          @media screen and (max-width: $bp-mobile-l) {
            grid-column: 1 / 5;
          }

          @media screen and (max-width: $bp-mobile-m) {
            padding-right: 24px;
            grid-column: 1 / 3;

            a,
            a span {
              font-size: var(--body-s);
            }
          }
        }

        &.main {
          grid-column: 3 / 6;
          border-left: 1px solid var(--sweet-liquorice-200);

          @media screen and (max-width: $bp-mobile-l) {
            grid-column: 5 / 9;
          }

          @media screen and (max-width: $bp-mobile-m) {
            padding-right: 24px;
            grid-column: 3 / 6;
          }
        }
      }

      .career-count {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: var(--spacing-s);
        background-color: var(--fresh-mint-300);
        border-radius: var(--radius-full);
        margin-left: var(--spacing-s);
        font-size: var(--body-s);

        @media screen and (max-width: $bp-mobile-m) {
          width: 18px;
          height: 18px;
          font-size: 11px !important;
        }
      }

      .career-container {
        display: flex;
        align-items: center;
        color: var(--button-tertiary-color);
        position: relative;

        @media (hover: hover) {
          &:hover .career {
            &::before {
              opacity: 0;
            }

            &::after {
              transform-origin: left top;
              transform: scale(1, 1);
            }
          }
        }
      }

      .career {
        --accent: var(--fresh-mint-600);
      }

      .main-items {
        display: grid;
        gap: var(--spacing-3-xl);
        grid-template-columns: auto;
        text-align: right;

        li {
          overflow: hidden;
        }

        a {
          cursor: pointer;
        }
      }

      .sub-items {
        display: grid;
        gap: var(--spacing-2-xl);
        grid-template-columns: auto;
        text-align: right;

        li {
          overflow: hidden;
        }

        a {
          cursor: pointer;
        }
      }

      .footer {
        display: flex;
        justify-content: end;
        padding: var(--spacing-2-xl);
        grid-column: -1/1;
        align-self: end;

        @media screen and (max-width: $bp-tablet-s) {
          display: grid;
          align-items: center;
          justify-content: center;
          gap: var(--spacing-2-xl);

          position: fixed;
          bottom: var(--spacing-2-xl);
          bottom: calc(env(safe-area-inset-bottom));

          > a {
            // stylelint-disable-next-line stylistic/max-line-length
            width: calc(100vw - var(--spacing-2-xl) - var(--spacing-2-xl));
          }
        }
      }

      .banner {
        position: absolute;
        left: 0;
        top: 116px;
        width: calc(100% - 2 * var(--spacing-2-xl));
        margin: 0 var(--spacing-2-xl);
        grid-column: -1/1;

        @media screen and (max-width: $bp-tablet-s) {
          top: auto;
          bottom: 160px;
        }

        @media screen
          and (max-width: $bp-tablet-s)
          and (max-height: 720px) {
          bottom: 132px;
        }
      }

      .contact {
        display: none;

        @media screen and (max-width: $bp-tablet-s) {
          display: block;
        }
      }

      .socials {
        display: inline-grid;
        gap: var(--spacing-2-xl);
        grid-auto-flow: column;

        @media screen and (max-width: $bp-tablet-s) {
          justify-content: space-around;
        }

        svg {
          width: 24px;
          height: 24px;
        }

        li,
        a {
          display: flex;
        }

        a {
          color: var(--sweet-liquorice-800);
          transition: color .2s ease;
          font-size: 0;

          &:hover {
            color: var(--sweet-liquorice-900);
          }
        }
      }
    }
  }
}

.menu {
  pointer-events: none;
  clip-path: circle(0% at 100% 0%);
  background-color: var(--sweet-liquorice-100);
  transition: none 1s var(--ease);
  transition-property: clip-path, background-color;
}

.main-items li a {
  &::before {
    border-radius: 0;
    box-sizing: content-box;
    content: '';
    display: block;
    height: 100%;
    left: 50%;
    padding: .5ch 2ch;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }
}

.is-expanded {
  .main-nav {
    background-color: var(--white-000);
  }

  .menu {
    clip-path: circle(150% at 100% 0%);
    background-color: var(--white-000);
    pointer-events: auto;
  }

  :global {
    .blowing {
      opacity: 0;
    }
  }
}
