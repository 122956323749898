// Media queries can't be used as native CSS variables (yet)
$bp-mobile-s: 320px !default;
$bp-mobile-m: 375px !default;
$bp-mobile-l: 414px !default;
$bp-tablet-s: 562px !default;
$bp-tablet-m: 768px !default;
$bp-tablet-l: 920px !default;
$bp-desktop-1-xs: 1024px !default;
$bp-desktop-s: 1120px !default;
$bp-mobile-nav: 1240px !default;
$bp-desktop-m: 1280px !default;
$bp-desktop-l: 1440px !default;
$bp-desktop-1-xl: 1650px !default;
$bp-desktop-2-xl: 1920px !default;

%heading {
  font-weight: 600;
  color: var(--sweet-liquorice-900);
  line-height: var(--leading-tight);
  letter-spacing: var(--tracking-normal);
}

%paragraph {
  font-weight: 500;
  color: var(--sweet-liquorice-750);
  line-height: var(--leading-normal);
  letter-spacing: var(--tracking-normal);
}

%quote {
  font-weight: 500;
  color: var(--sweet-liquorice-900);
  line-height: var(--leading-normal);
  letter-spacing: var(--tracking-normal);
}

%link {
  font-weight: 500;
  text-decoration: underline;
  color: var(--sweet-liquorice-900);
  line-height: var(--leading-none);
  letter-spacing: var(--tracking-normal);
}

%button {
  font-weight: 600;
  line-height: var(--leading-normal);
  letter-spacing: var(--tracking-normal);
}

.inversed {
  %heading {
    color: var(--white-000);
  }

  %paragraph,
  %quote,
  %link {
    color: var(--sweet-liquorice-300);
  }
}

@mixin h1 {
  @extend %heading;
  font-size: var(--heading-6-xl);
}

@mixin h2 {
  @extend %heading;
  font-size: var(--heading-3-xl);
}

@mixin h3 {
  @extend %heading;
  font-size: var(--heading-2-xl);
}

@mixin h4 {
  @extend %heading;
  font-size: var(--heading-1-xl);
}

@mixin h5 {
  @extend %heading;
  font-size: var(--heading-l);
}

@mixin h6 {
  @extend %heading;
  font-size: var(--heading-m);
}

@mixin streamer {
  @extend %paragraph;
  font-size: var(--streamer-size);
  font-weight: 500;
}

@mixin quote-1-xl {
  @extend %quote;
  font-size: var(--heading-2-xl);
}

@mixin quote-l {
  @extend %quote;
  font-size: var(--heading-l);
}

@mixin body-s {
  @extend %paragraph;
  font-size: var(--body-s);
}

@mixin body-m {
  @extend %paragraph;
  font-size: var(--body-m);
}

@mixin body-l {
  @extend %paragraph;
  font-size: var(--body-l);
}

@mixin body-1-xl {
  @extend %paragraph;
  font-size: var(--body-1-xl);
}

@mixin body-2-xl {
  @extend %paragraph;
  font-size: var(--body-2-xl);
}

@mixin textlink-l {
  @extend %link;
  font-weight: 600;
}

@mixin textlink-m {
  @extend %link;
}

@mixin textlink-s {
  @extend %link;
}

@mixin caption {
  font-weight: 500;
}

@mixin eyebrow {
  text-transform: uppercase;
  font-weight: 600;
  font-size: var(--heading-s);
  color: var(--sweet-liquorice-500);
}

@mixin footerlink-m {
  color: var(--sweet-liquorice-100);
}

@mixin footerlink-s {
  color: var(--sweet-liquorice-100);
}

@mixin button-l {
  @extend %button;
  font-size: var(--body-l);
}

@mixin button-m {
  @extend %button;
  font-size: var(--body-m);
}

@mixin button-s {
  @extend %button;
  font-size: var(--body-s);
}

// DEFAULT

// Heavy
@font-face {
  font-family: 'Dazzed';
  src:
    url('/fonts/Dazzed-Heavy.woff2') format('woff2'),
    url('/fonts/Dazzed-Heavy.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: optional;
}

// Bold
@font-face {
  font-family: 'Dazzed';
  src:
    url('/fonts/Dazzed-Bold.woff2') format('woff2'),
    url('/fonts/Dazzed-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: optional;
}

// Semi-Bold
@font-face {
  font-family: 'Dazzed';
  src:
    url('/fonts/Dazzed-SemiBold.woff2') format('woff2'),
    url('/fonts/Dazzed-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: optional;
}

// Medium
@font-face {
  font-family: 'Dazzed';
  src:
    url('/fonts/Dazzed-Medium.woff2') format('woff2'),
    url('/fonts/Dazzed-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: optional;
}

// ITALIC

// Heavy Italic
@font-face {
  font-family: 'Dazzed';
  src:
    url('/fonts/Dazzed-HeavyItalic.woff2') format('woff2'),
    url('/fonts/Dazzed-HeavyItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: optional;
}

// Bold Italic
@font-face {
  font-family: 'Dazzed';
  src:
    url('/fonts/Dazzed-BoldItalic.woff2') format('woff2'),
    url('/fonts/Dazzed-BoldItalic.woff') format('woff');
  font-weight: 700;
  font-style: italic;
  font-display: optional;
}

// Semi-Bold Italic
@font-face {
  font-family: 'Dazzed';
  src:
    url('/fonts/Dazzed-SemiBoldItalic.woff2') format('woff2'),
    url('/fonts/Dazzed-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: optional;
}

// Medium Italic
@font-face {
  font-family: 'Dazzed';
  src:
    url('/fonts/Dazzed-MediumItalic.woff2') format('woff2'),
    url('/fonts/Dazzed-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: optional;
}

.footer {
  background-color: var(--sweet-liquorice-900);
  overflow-y: hidden;
  width: 100%;

  // Applied because of performance improvements
  // ? see here https://web.dev/content-visibility/
  content-visibility: auto;
  contain-intrinsic-size: 650px;

  @media screen and (max-width: $bp-tablet-m) {
    contain-intrinsic-size: 1200px;
  }

  @media screen and (max-width: $bp-tablet-s) {
    contain-intrinsic-size: 900px;
  }

  address {
    font-style: normal;
    color: var(--sweet-liquorice-400);
  }
}

.main {
  :global {
    .underline {
      --accent: var(--sweet-liquorice-300);

      // ? This adds spacing between underline and text
      // ? but prevents adding extra spacing at the bottom
      margin-bottom: -8px;
      padding-bottom: 8px;
    }
  }

  p,
  a {
    color: var(--sweet-liquorice-100);

    --accent: var(--sweet-liquorice-100);

    &::selection {
      background-color: var(--selection-sweet-liquorice);
    }
  }
}

.inner {
  opacity: 0;
  transition: opacity .3s var(--ease);

  // Start opacity transition halfway
  transition-delay: .3s;

  &.fade {
    opacity: 1;
  }

  @media screen and (max-width: $bp-tablet-l) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.content {
  background-color: var(--black-000);
  clip-path: circle(0% at 50% 0%);
  padding: 88px 0 0;
  transition:
    clip-path .6s var(--ease),
    background-color .6s var(--ease);

  &.masked {
    background-color: var(--sweet-liquorice-900);
    clip-path: circle(150% at 50% 0%);
  }

  &.compact {
    padding: 56px 0 0;

    > div {
      --gap: 44px;
    }

    .logo-container {
      margin: 0;
    }

    .sitemap-container {
      border-bottom: 0;

      @media screen and (max-width: $bp-desktop-s) {
        padding: 0;
      }

      .sitemap-inner {
        flex-direction: row;
        align-items: center;
        display: flex;
        gap: var(--spacing-2-xl);

        @media screen and (max-width: $bp-desktop-s) {
          align-items: start;
          flex-direction: column;
        }
      }

      .sitemap {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: var(--spacing-2-xl);

        @media screen and (max-width: $bp-desktop-s) {
          flex-direction: column;
          align-items: start;
          gap: var(--spacing-2-xl);

          > li:first-child {
            margin-top: 0;
          }
        }

        > li {
          display: flex;
          flex-direction: row;
          border: none;
          padding: 0;

          a {
            font-size: var(--body-l);
          }

          > ul {
            display: none;
          }
        }
      }

      @media screen and (max-width: $bp-tablet-l) {
        .sitemap-inner,
        .sitemap {
          flex-direction: column;
          align-items: start;
        }
      }
    }

    .right {
      padding: 0;
      border: none;

      @media screen and (max-width: $bp-tablet-l) {
        display: none;
      }

      @media screen and (max-width: $bp-desktop-s) {
        align-items: end;
        justify-content: end;
        height: 100%;
        padding-left: 112px;
        border-left: 1px solid var(--sweet-liquorice-700);
      }

      .newsletter {
        display: none;
      }

      .channels {
        padding: 0;
        justify-content: flex-end;
        flex-direction: row;
        margin-right: -24px;
        margin-top: -4px;
        gap: var(--spacing-s);

        @media screen and (max-width: $bp-desktop-s) {
          flex-direction: column;
          justify-content: end;
          height: 100%;
        }
      }
    }

    .mobile-socials {
      @media screen and (max-width: $bp-tablet-l) {
        padding-left: var(--spacing-5-xl);
      }

      @media screen and (max-width: $bp-tablet-s) {
        padding-left: var(--spacing-1-xl);
      }
    }

    .sub-footer {
      margin-top: 0;
    }
  }

  @media screen and (max-width: $bp-tablet-l) {
    padding: 60px 0 0;
  }
}

.logo-container {
  display: inline-flex;
  margin-bottom: var(--spacing-2-xl);

  @media screen and (max-width: $bp-tablet-l) {
    margin-bottom: 0;
  }
}

.logo {
  height: 40px;
  width: 43px;
  display: block;

  @media screen and (max-width: $bp-tablet-s) {
    height: 32px;
    width: 34px;
  }
}

.sitemap-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-right: 64px;

  @media screen and (max-width: $bp-tablet-l) {
    padding-right: 0;
  }
}

.sitemap {
  > li {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    padding: 20px 0;
    column-gap: var(--spacing-3-xl);
    border-top: 1px solid var(--sweet-liquorice-700);

    &:last-child {
      padding-bottom: 0;
    }

    > div {
      grid-area: 1 / 1 / 2 / 3;

      > a {
        font-weight: 600;
      }
    }

    > ul {
      grid-area: 1 / 4 / 2 / 6;
      display: flex;
      flex-direction: column;
      gap: var(--spacing-m);

      li {
        padding: 0;
      }
    }

    @media screen and (max-width: $bp-tablet-l) {
      grid-template-columns: 1fr;

      &:first-child {
        margin-top: var(--spacing-1-xl);
        border-top: 1px solid var(--sweet-liquorice-700);
      }

      > div,
      > ul {
        grid-area: auto;
      }

      > ul {
        padding: 20px 0 4px;
        gap: var(--spacing-1-xl);
      }
    }
  }

  .career-count {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: var(--spacing-s);
    background-color: var(--fresh-mint-300);
    border-radius: var(--radius-full);
    margin-left: 8px;
    font-size: var(--body-s);
  }

  .career-container {
    display: inline-flex;
    align-items: center;
    position: relative;
  }
}

.mobile-socials {
  display: none;
}

@media screen and (max-width: $bp-tablet-l) {
  .sitemap-container {
    flex-direction: row;
    border-bottom: 1px solid var(--sweet-liquorice-700);
    padding-bottom: var(--spacing-4-xl);
  }

  .sitemap-inner {
    width: 50%;
    padding: 0 var(--spacing-2-xl);
  }

  .mobile-socials {
    width: 50%;
    display: flex;
    align-items: flex-end;
    padding-left: var(--spacing-l);
    border-left: 1px solid var(--sweet-liquorice-700);

    .channels {
      display: flex;
    }
  }
}

.right {
  padding-left: 112px;
  border-left: 1px solid var(--sweet-liquorice-700);

  @media screen and (max-width: $bp-desktop-s) {
    padding-left: 84px;
  }

  @media screen and (max-width: $bp-tablet-l) {
    padding-left: 0;
    border-left: 0;
  }
}

.newsletter {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-1-xl);
  border-radius: var(--radius-1-xs);
  max-width: 408px;

  p {
    color: var(--sweet-liquorice-300);
    line-height: var(--leading-normal);
    padding-left: 24px;
  }

  input {
    --input-hover-bg-color: var(--sweet-liquorice-900);
  }

  .form {
    display: flex;
    align-items: flex-end;

    > div {
      width: 100%;
    }
  }

  @media screen and (max-width: $bp-tablet-l) {
    padding: 0 var(--spacing-2-xl);
    max-width: 712px;

    p {
      padding-left: 24px;
    }
  }
}

.channels {
  padding-top: 64px;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-l);

  span {
    gap: 4px;
  }

  a {
    --bg-color: var(--sweet-liquorice-100);
    --button-primary-color: var(--sweet-liquorice-100);
    --button-primary-hover-bg-color: var(--sweet-liquorice-100);
    /* stylelint-disable-next-line stylistic/max-line-length */
    --button-primary-hover-negative-bg-color: var(--sweet-liquorice-100);
    --button-primary-hover-color: var(--sweet-liquorice-900);

    font-weight: 600;
  }

  @media screen and (max-width: $bp-tablet-l) {
    display: none;
  }
}

.submitted {
  height: 92px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    overflow: hidden;
    color: white;
  }

  span {
    display: flex;
  }
}

.door {
  transform-origin: 37.54px 24.51px !important;
}

.sub-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid var(--sweet-liquorice-700);
  padding: var(--spacing-1-xl) 0;
  margin-top: var(--spacing-m);

  @media screen and (max-width: $bp-tablet-l) {
    flex-direction: column;
    padding: var(--spacing-1-xl) 0 var(--spacing-3-xl);
  }
}

.sub-footer-links {
  display: flex;
  gap: var(--spacing-m);
  align-items: center;

  a,
  span {
    color: var(--sweet-liquorice-300);
  }

  @media screen and (max-width: $bp-tablet-l) {
    gap: var(--spacing-l);
  }
}

.copyright-notice {
  display: flex;
  align-items: center;
  gap: var(--spacing-m);

  @media screen and (max-width: $bp-tablet-l) {
    flex-direction: column-reverse;
    align-items: center;
    gap: 0;
  }

  p,
  a {
    color: var(--sweet-liquorice-400);

    &::selection {
      background-color: var(--selection-sweet-liquorice);
    }
  }

  @media screen and (max-width: $bp-tablet-l) {
    .bullet {
      display: none;
    }

    svg {
      margin: var(--spacing-1-xl) 0 var(--spacing-l);
    }
  }
}
