// Media queries can't be used as native CSS variables (yet)
$bp-mobile-s: 320px !default;
$bp-mobile-m: 375px !default;
$bp-mobile-l: 414px !default;
$bp-tablet-s: 562px !default;
$bp-tablet-m: 768px !default;
$bp-tablet-l: 920px !default;
$bp-desktop-1-xs: 1024px !default;
$bp-desktop-s: 1120px !default;
$bp-mobile-nav: 1240px !default;
$bp-desktop-m: 1280px !default;
$bp-desktop-l: 1440px !default;
$bp-desktop-1-xl: 1650px !default;
$bp-desktop-2-xl: 1920px !default;

%heading {
  font-weight: 600;
  color: var(--sweet-liquorice-900);
  line-height: var(--leading-tight);
  letter-spacing: var(--tracking-normal);
}

%paragraph {
  font-weight: 500;
  color: var(--sweet-liquorice-750);
  line-height: var(--leading-normal);
  letter-spacing: var(--tracking-normal);
}

%quote {
  font-weight: 500;
  color: var(--sweet-liquorice-900);
  line-height: var(--leading-normal);
  letter-spacing: var(--tracking-normal);
}

%link {
  font-weight: 500;
  text-decoration: underline;
  color: var(--sweet-liquorice-900);
  line-height: var(--leading-none);
  letter-spacing: var(--tracking-normal);
}

%button {
  font-weight: 600;
  line-height: var(--leading-normal);
  letter-spacing: var(--tracking-normal);
}

.inversed {
  %heading {
    color: var(--white-000);
  }

  %paragraph,
  %quote,
  %link {
    color: var(--sweet-liquorice-300);
  }
}

@mixin h1 {
  @extend %heading;
  font-size: var(--heading-6-xl);
}

@mixin h2 {
  @extend %heading;
  font-size: var(--heading-3-xl);
}

@mixin h3 {
  @extend %heading;
  font-size: var(--heading-2-xl);
}

@mixin h4 {
  @extend %heading;
  font-size: var(--heading-1-xl);
}

@mixin h5 {
  @extend %heading;
  font-size: var(--heading-l);
}

@mixin h6 {
  @extend %heading;
  font-size: var(--heading-m);
}

@mixin streamer {
  @extend %paragraph;
  font-size: var(--streamer-size);
  font-weight: 500;
}

@mixin quote-1-xl {
  @extend %quote;
  font-size: var(--heading-2-xl);
}

@mixin quote-l {
  @extend %quote;
  font-size: var(--heading-l);
}

@mixin body-s {
  @extend %paragraph;
  font-size: var(--body-s);
}

@mixin body-m {
  @extend %paragraph;
  font-size: var(--body-m);
}

@mixin body-l {
  @extend %paragraph;
  font-size: var(--body-l);
}

@mixin body-1-xl {
  @extend %paragraph;
  font-size: var(--body-1-xl);
}

@mixin body-2-xl {
  @extend %paragraph;
  font-size: var(--body-2-xl);
}

@mixin textlink-l {
  @extend %link;
  font-weight: 600;
}

@mixin textlink-m {
  @extend %link;
}

@mixin textlink-s {
  @extend %link;
}

@mixin caption {
  font-weight: 500;
}

@mixin eyebrow {
  text-transform: uppercase;
  font-weight: 600;
  font-size: var(--heading-s);
  color: var(--sweet-liquorice-500);
}

@mixin footerlink-m {
  color: var(--sweet-liquorice-100);
}

@mixin footerlink-s {
  color: var(--sweet-liquorice-100);
}

@mixin button-l {
  @extend %button;
  font-size: var(--body-l);
}

@mixin button-m {
  @extend %button;
  font-size: var(--body-m);
}

@mixin button-s {
  @extend %button;
  font-size: var(--body-s);
}

// DEFAULT

// Heavy
@font-face {
  font-family: 'Dazzed';
  src:
    url('/fonts/Dazzed-Heavy.woff2') format('woff2'),
    url('/fonts/Dazzed-Heavy.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: optional;
}

// Bold
@font-face {
  font-family: 'Dazzed';
  src:
    url('/fonts/Dazzed-Bold.woff2') format('woff2'),
    url('/fonts/Dazzed-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: optional;
}

// Semi-Bold
@font-face {
  font-family: 'Dazzed';
  src:
    url('/fonts/Dazzed-SemiBold.woff2') format('woff2'),
    url('/fonts/Dazzed-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: optional;
}

// Medium
@font-face {
  font-family: 'Dazzed';
  src:
    url('/fonts/Dazzed-Medium.woff2') format('woff2'),
    url('/fonts/Dazzed-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: optional;
}

// ITALIC

// Heavy Italic
@font-face {
  font-family: 'Dazzed';
  src:
    url('/fonts/Dazzed-HeavyItalic.woff2') format('woff2'),
    url('/fonts/Dazzed-HeavyItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: optional;
}

// Bold Italic
@font-face {
  font-family: 'Dazzed';
  src:
    url('/fonts/Dazzed-BoldItalic.woff2') format('woff2'),
    url('/fonts/Dazzed-BoldItalic.woff') format('woff');
  font-weight: 700;
  font-style: italic;
  font-display: optional;
}

// Semi-Bold Italic
@font-face {
  font-family: 'Dazzed';
  src:
    url('/fonts/Dazzed-SemiBoldItalic.woff2') format('woff2'),
    url('/fonts/Dazzed-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: optional;
}

// Medium Italic
@font-face {
  font-family: 'Dazzed';
  src:
    url('/fonts/Dazzed-MediumItalic.woff2') format('woff2'),
    url('/fonts/Dazzed-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: optional;
}

@keyframes appear {
  0% {
    opacity: 0;
    transform: translate3d(0, 30px, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.banner {
  background-color: var(--fresh-mint-200);
  padding: var(--spacing-l);
  width: 100%;
  border-radius: var(--radius-1-xs);
  display: inline-grid;
  grid-template-columns: 1fr 48px;
  align-items: center;
  gap: var(--spacing-l);

  @media screen and (max-width: $bp-tablet-s) {
    padding: var(--spacing-2-xl) var(--spacing-1-xl);
  }

  &::before {
    content: '';
    left: 412px;
    top: -11px;
    width: 23px;
    height: 23px;
    transform: rotate(45deg);
    border-radius: var(--radius-1-xs);
    background-color: var(--fresh-mint-200);
    position: absolute;

    @media screen and (max-width: $bp-desktop-l) {
      left: 244px;
    }

    @media screen and (max-width: $bp-tablet-s) {
      left: 50%;
      top: auto;
      bottom: -12px;
      transform: translateX(-12px) rotate(45deg);
      transform-origin: center;
    }

    @media screen
      and (max-width: $bp-tablet-s)
      and (max-height: 720px) {
      display: none;
    }

    @media screen and (min-width: $bp-desktop-1-xl) {
      left: unset;
      right: 185px;
    }
  }

  h2 {
    margin-bottom: var(--spacing-1-xs);
    overflow: hidden;
    position: relative;
    height: calc(var(--leading-tight) * var(--heading-s));

    &::selection {
      background-color: var(--selection-fresh-mint-200);
    }

    span {
      --delay: 1100ms;

      transform: translate3d(0, 20px, 0);

      animation: appear .4s cubic-bezier(.79, .14, .15, .86) forwards;
      animation-delay: var(--delay);
      position: absolute;
    }
  }

  .shop {
    width: 45px;
    height: 48px;
  }

  .content {
    width: 100%;
    position: relative;
    overflow: hidden;

    &.done {
      overflow: initial;
    }
  }

  p {
    --delay: 1250ms;

    position: relative;
    display: inline-block;
    transform: translate3d(0, 20px, 0);

    animation: appear .4s cubic-bezier(.79, .14, .15, .86) forwards;
    animation-delay: var(--delay);
  }
}

.door {
  transform-origin: 37.54px 24.51px !important;
}

.squiggle {
  position: absolute;
  right: -16px;
  top: calc(var(--leading-normal) * var(--body-s));
}
