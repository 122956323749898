// stylelint-disable stylistic/max-line-length
@use 'sass:map';
@use 'sass:math';
@use 'sass:list';
@use 'media-queries';

:root {
  // Min: 50px Max: 80px
  --type-scale-9-xl: clamp(3.125rem, 1.7857rem + 6.6964vw, 5rem);
  // Min: 46px Max: 68px
  --type-scale-8-xl: clamp(2.875rem, 1.8929rem + 4.9107vw, 4.25rem);
  // Min: 40px Max: 58px
  --type-scale-7-xl: clamp(2.5rem, 1.6964rem + 4.0179vw, 3.625rem);
  // Min: 36px Max: 48px
  --type-scale-6-xl: clamp(2.25rem, 1.7143rem + 2.6786vw, 3rem);
  // Min: 32px Max: 42px
  --type-scale-5-xl: clamp(2rem, 1.5536rem + 2.2321vw, 2.625rem);
  // Min: 28px Max: 36px
  --type-scale-4-xl: clamp(1.75rem, 1.3929rem + 1.7857vw, 2.25rem);
  // Min: 26px Max: 30px
  --type-scale-3-xl: clamp(1.625rem, 1.4464rem + 0.8929vw, 1.875rem);
  // Min: 22px Max: 26px
  --type-scale-2-xl: clamp(1.375rem, 1.1964rem + 0.8929vw, 1.625rem);
  // Min: 20px Max: 22px
  --type-scale-1-xl: clamp(1.25rem, 1.1607rem + 0.4464vw, 1.375rem);
  // Min: 18px Max: 18px
  --type-scale-l: 1.125rem;
  // Min: 16px Max: 16px
  --type-scale-m: 1rem;
  // Min: 14px Max: 14px
  --type-scale-s: 0.875rem;
  // Min: 13px Max: 13px
  --type-scale-1-xs: 0.8125rem;

  --heading-9-xl: var(--type-scale-9-xl);
  --heading-8-xl: var(--type-scale-8-xl);
  --heading-7-xl: var(--type-scale-7-xl);
  --heading-6-xl: var(--type-scale-6-xl);
  --heading-5-xl: var(--type-scale-5-xl);
  --heading-4-xl: var(--type-scale-4-xl);
  --heading-3-xl: var(--type-scale-3-xl);
  --heading-2-xl: var(--type-scale-2-xl);
  --heading-1-xl: var(--type-scale-1-xl);
  --heading-l: var(--type-scale-l);
  --heading-m: var(--type-scale-m);
  --heading-s: var(--type-scale-s);
  --heading-1-xs: var(--type-scale-1-xs);
  --body-2-xl: var(--type-scale-2-xl);
  --body-1-xl: var(--type-scale-1-xl);
  --body-l: var(--type-scale-l);
  --body-m: var(--type-scale-m);
  --body-s: var(--type-scale-s);
  --body-1-xs: var(--type-scale-1-xs);

  @media screen and (min-width: media-queries.$bp-tablet-m) {
    // Min: 80px Max: 148px
    --type-scale-9-xl: clamp(5rem, 0.1429rem + 10.119vw, 9.25rem);
    // Min: 68px Max: 120px
    --type-scale-8-xl: clamp(4.25rem, 0.5357rem + 7.7381vw, 7.5rem);
    // Min: 58px Max: 96px
    --type-scale-7-xl: clamp(3.625rem, 0.9107rem + 5.6548vw, 6rem);
    // Min: 48px Max: 76px
    --type-scale-6-xl: clamp(3rem, 1rem + 4.1667vw, 4.75rem);
    // Min: 42px Max: 62px
    --type-scale-5-xl: clamp(2.625rem, 1.1964rem + 2.9762vw, 3.875rem);
    // Min: 36px Max: 48px
    --type-scale-4-xl: clamp(2.25rem, 1.3929rem + 1.7857vw, 3rem);
    // Min: 30px Max: 40px
    --type-scale-3-xl: clamp(1.875rem, 1.1607rem + 1.4881vw, 2.5rem);
    // Min: 26px Max: 32px
    --type-scale-2-xl: clamp(1.625rem, 1.1964rem + 0.8929vw, 2rem);
    // Min: 22px Max: 24px
    --type-scale-1-xl: clamp(1.375rem, 1.2321rem + 0.2976vw, 1.5rem);
    // Min: 18px Max: 20px
    --type-scale-l: clamp(1.125rem, 0.9821rem + 0.2976vw, 1.25rem);
    // Min: 16px Max: 16px
    --type-scale-m: 1rem;
    // Min: 14px Max: 14px
    --type-scale-s: 0.875rem;
    // Min: 13px Max: 12px
    --type-scale-1-xs: clamp(0.8125rem, 0.8839rem + -0.1488vw, 0.75rem);
  }

  @media screen and (min-width: media-queries.$bp-desktop-l) {
    // Min: 148px Max: 178px
    --type-scale-9-xl: clamp(9.25rem, 3.625rem + 6.25vw, 11.125rem);
    // Min: 120px Max: 142px
    --type-scale-8-xl: clamp(7.5rem, 3.375rem + 4.5833vw, 8.875rem);
    // Min: 96px Max: 110px
    --type-scale-7-xl: clamp(6rem, 3.375rem + 2.9167vw, 6.875rem);
    // Min: 76px Max: 86px
    --type-scale-6-xl: clamp(4.75rem, 2.875rem + 2.0833vw, 5.375rem);
    // Min: 62px Max: 70px
    --type-scale-5-xl: clamp(3.875rem, 2.375rem + 1.6667vw, 4.375rem);
    // Min: 48px Max: 58px
    --type-scale-4-xl: clamp(3rem, 1.125rem + 2.0833vw, 3.625rem);
    // Min: 40px Max: 46px
    --type-scale-3-xl: clamp(2.5rem, 1.375rem + 1.25vw, 2.875rem);
    // Min: 32px Max: 36px
    --type-scale-2-xl: clamp(2rem, 1.25rem + 0.8333vw, 2.25rem);
    // Min: 24px Max: 26px
    --type-scale-1-xl: clamp(1.5rem, 1.125rem + 0.4167vw, 1.625rem);
    // Min: 20px Max: 22px
    --type-scale-l: clamp(1.25rem, 0.875rem + 0.4167vw, 1.375rem);
    // Min: 16px Max: 18px
    --type-scale-m:  clamp(1rem, 0.625rem + 0.4167vw, 1.125rem);
    // Min: 14px Max: 16px
    --type-scale-s: clamp(0.875rem, 0.5rem + 0.4167vw, 1rem);
    // Min: 12px Max: 14px
    --type-scale-1-xs: clamp(0.75rem, 0.375rem + 0.4167vw, 0.875rem);
  }

  // Define font
  --font-stack-base:
    'Dazzed',
    system-ui,
    -apple-system,
    'SF Pro Display',
    'BlinkMacSystemFont',
    'Helvetica',
    'Lato',
    'Arial',
    'Segoe UI',
    'Verdana',
    sans-serif;

  // Define line-height
  // Based on TailwindCSS: https://tailwindcss.com/docs/line-height
  --leading-none: 1;
  --leading-tight: 1.25;
  --leading-snug: 1.375;
  --leading-normal: 1.5;
  --leading-relaxed: 1.625;
  --leading-loose: 2;

  // Define letter-spacing
  // Based on TailwindCSS: https://tailwindcss.com/docs/letter-spacing
  --tracking-tighter: -.05em;
  --tracking-tight: -.025em;
  --tracking-normal: 0;
  --tracking-wide: .025em;
  --tracking-wider: .05em;
  --tracking-widest: .1em;
}
