@use '../config/fonts';

h1 {
  @include fonts.h1;
}

h2 {
  @include fonts.h2;
}

h3 {
  @include fonts.h3;
}

h4 {
  @include fonts.h4;
}

h5 {
  @include fonts.h5;
}

h6 {
  @include fonts.h6;
}

.heading-s {
  @extend %heading;
  font-size: var(--heading-s);
}

.heading-m {
  @extend %heading;
  font-size: var(--heading-m);
}

.heading-l {
  @extend %heading;
  font-size: var(--heading-l);
}

.heading-1-xl {
  @extend %heading;
  font-size: var(--heading-1-xl);
}

.heading-2-xl {
  @extend %heading;
  font-size: var(--heading-2-xl);
}

.heading-3-xl {
  @extend %heading;
  font-size: var(--heading-3-xl);
}

.heading-4-xl {
  @extend %heading;
  font-size: var(--heading-4-xl);
}

.heading-5-xl {
  @extend %heading;
  font-size: var(--heading-5-xl);
}

.heading-6-xl {
  @extend %heading;
  font-size: var(--heading-6-xl);
}

.heading-7-xl {
  @extend %heading;
  font-size: var(--heading-7-xl);
}

.heading-8-xl {
  @extend %heading;
  font-size: var(--heading-8-xl);
}

.heading-9-xl {
  @extend %heading;
  font-size: var(--heading-9-xl);
}

p,
q,
blockquote {
  @extend %paragraph;

  &.streamer {
    @include fonts.streamer;
  }

  &.quote-1-xl {
    @include fonts.quote-1-xl;
  }

  &.quote-l {
    @include fonts.quote-l;
  }
}

.body-s {
  @include fonts.body-s;
}

.body-m {
  @include fonts.body-m;
}

.body-l {
  @include fonts.body-l;
}

.body-1-xl {
  @include fonts.body-1-xl;
}

.body-2-xl {
  @include fonts.body-2-xl;
}

.textlink-l {
  @include fonts.textlink-l;
}

.textlink-m {
  @include fonts.textlink-m;
}

.textlink-s {
  @include fonts.textlink-s;
}

.caption {
  @include fonts.caption;
}

.eyebrow {
  @include fonts.eyebrow;
}

.footerlink-m {
  @include fonts.footerlink-m;
}

.footerlink-s {
  @include fonts.footerlink-s;
}

.button-s {
  @include fonts.button-s;
}

.button-m {
  @include fonts.button-m;
}

.button-l {
  @include fonts.button-l;
}

strong {
  font-weight: 600;
}

.underline {
  --accent: var(--sweet-liquorice-900);
  --width: 2px;
  --size: 0%;

  position: relative;
  display: inline-block;
  text-decoration: none;
  color: currentcolor;
  line-height: var(--leading-normal);

  &::before,
  &::after {
    position: absolute;
    left: 0;
    content: '';
    width: 100%;
    height: var(--width);
    border-radius: 1em;
  }

  &::before {
    transform: scale(1, 1);
    bottom: 0;
  }

  &::after {
    background: var(--accent);
    bottom: 0;
    transform: scale(0, 1);
    transition: transform 300ms;
    transform-origin: right top;
  }

  &:focus {
    outline: none;

    &::before {
      opacity: 0;
    }

    &::after {
      transform-origin: left top;
      transform: scale(1, 1);
    }
  }

  @media (hover: hover) {
    &:hover {
      &::before {
        opacity: 0;
      }

      &::after {
        transform-origin: left top;
        transform: scale(1, 1);
      }
    }
  }
}

.underline-inline {
  --accent: var(--sweet-liquorice-900);
  --width: 2px;
  --size: 0%;

  a {
    position: relative;
    display: inline-block;
    text-decoration: none;
    font-weight: 600;
    color: var(--sweet-liquorice-750);
    line-height: var(--leading-normal);

    &::before,
    &::after {
      position: absolute;
      left: 0;
      content: '';
      width: 100%;
      height: var(--width);
      border-radius: 1em;
      background: var(--accent);
      bottom: 0;
    }

    &::before {
      transform: scale(1, 1);
      transition: transform 300ms;
      transform-origin: left top;
    }

    &::after {
      transform: scale(0, 1);
      transition: transform 300ms 200ms;
      transform-origin: right top;
    }

    &:focus {
      outline: none;

      &::before {
        transform-origin: left top;
        transform: scale(1, 1);
      }

      &::after {
        opacity: 0;
      }
    }

    @media (hover: hover) {
      &:hover {
        &::before {
          transform-origin: right top;
          transform: scale(0, 1);
        }

        &::after {
          transform-origin: left top;
          transform: scale(1, 1);
        }
      }
    }
  }
}

@mixin list-style {
  content: '';
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  height: 12px;
  text-align: center;
  width: 12px;
}
