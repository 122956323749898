// Media queries can't be used as native CSS variables (yet)
$bp-mobile-s: 320px !default;
$bp-mobile-m: 375px !default;
$bp-mobile-l: 414px !default;
$bp-tablet-s: 562px !default;
$bp-tablet-m: 768px !default;
$bp-tablet-l: 920px !default;
$bp-desktop-1-xs: 1024px !default;
$bp-desktop-s: 1120px !default;
$bp-mobile-nav: 1240px !default;
$bp-desktop-m: 1280px !default;
$bp-desktop-l: 1440px !default;
$bp-desktop-1-xl: 1650px !default;
$bp-desktop-2-xl: 1920px !default;

%heading {
  font-weight: 600;
  color: var(--sweet-liquorice-900);
  line-height: var(--leading-tight);
  letter-spacing: var(--tracking-normal);
}

%paragraph {
  font-weight: 500;
  color: var(--sweet-liquorice-750);
  line-height: var(--leading-normal);
  letter-spacing: var(--tracking-normal);
}

%quote {
  font-weight: 500;
  color: var(--sweet-liquorice-900);
  line-height: var(--leading-normal);
  letter-spacing: var(--tracking-normal);
}

%link {
  font-weight: 500;
  text-decoration: underline;
  color: var(--sweet-liquorice-900);
  line-height: var(--leading-none);
  letter-spacing: var(--tracking-normal);
}

%button {
  font-weight: 600;
  line-height: var(--leading-normal);
  letter-spacing: var(--tracking-normal);
}

.inversed {
  %heading {
    color: var(--white-000);
  }

  %paragraph,
  %quote,
  %link {
    color: var(--sweet-liquorice-300);
  }
}

@mixin h1 {
  @extend %heading;
  font-size: var(--heading-6-xl);
}

@mixin h2 {
  @extend %heading;
  font-size: var(--heading-3-xl);
}

@mixin h3 {
  @extend %heading;
  font-size: var(--heading-2-xl);
}

@mixin h4 {
  @extend %heading;
  font-size: var(--heading-1-xl);
}

@mixin h5 {
  @extend %heading;
  font-size: var(--heading-l);
}

@mixin h6 {
  @extend %heading;
  font-size: var(--heading-m);
}

@mixin streamer {
  @extend %paragraph;
  font-size: var(--streamer-size);
  font-weight: 500;
}

@mixin quote-1-xl {
  @extend %quote;
  font-size: var(--heading-2-xl);
}

@mixin quote-l {
  @extend %quote;
  font-size: var(--heading-l);
}

@mixin body-s {
  @extend %paragraph;
  font-size: var(--body-s);
}

@mixin body-m {
  @extend %paragraph;
  font-size: var(--body-m);
}

@mixin body-l {
  @extend %paragraph;
  font-size: var(--body-l);
}

@mixin body-1-xl {
  @extend %paragraph;
  font-size: var(--body-1-xl);
}

@mixin body-2-xl {
  @extend %paragraph;
  font-size: var(--body-2-xl);
}

@mixin textlink-l {
  @extend %link;
  font-weight: 600;
}

@mixin textlink-m {
  @extend %link;
}

@mixin textlink-s {
  @extend %link;
}

@mixin caption {
  font-weight: 500;
}

@mixin eyebrow {
  text-transform: uppercase;
  font-weight: 600;
  font-size: var(--heading-s);
  color: var(--sweet-liquorice-500);
}

@mixin footerlink-m {
  color: var(--sweet-liquorice-100);
}

@mixin footerlink-s {
  color: var(--sweet-liquorice-100);
}

@mixin button-l {
  @extend %button;
  font-size: var(--body-l);
}

@mixin button-m {
  @extend %button;
  font-size: var(--body-m);
}

@mixin button-s {
  @extend %button;
  font-size: var(--body-s);
}

// DEFAULT

// Heavy
@font-face {
  font-family: 'Dazzed';
  src:
    url('/fonts/Dazzed-Heavy.woff2') format('woff2'),
    url('/fonts/Dazzed-Heavy.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: optional;
}

// Bold
@font-face {
  font-family: 'Dazzed';
  src:
    url('/fonts/Dazzed-Bold.woff2') format('woff2'),
    url('/fonts/Dazzed-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: optional;
}

// Semi-Bold
@font-face {
  font-family: 'Dazzed';
  src:
    url('/fonts/Dazzed-SemiBold.woff2') format('woff2'),
    url('/fonts/Dazzed-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: optional;
}

// Medium
@font-face {
  font-family: 'Dazzed';
  src:
    url('/fonts/Dazzed-Medium.woff2') format('woff2'),
    url('/fonts/Dazzed-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: optional;
}

// ITALIC

// Heavy Italic
@font-face {
  font-family: 'Dazzed';
  src:
    url('/fonts/Dazzed-HeavyItalic.woff2') format('woff2'),
    url('/fonts/Dazzed-HeavyItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: optional;
}

// Bold Italic
@font-face {
  font-family: 'Dazzed';
  src:
    url('/fonts/Dazzed-BoldItalic.woff2') format('woff2'),
    url('/fonts/Dazzed-BoldItalic.woff') format('woff');
  font-weight: 700;
  font-style: italic;
  font-display: optional;
}

// Semi-Bold Italic
@font-face {
  font-family: 'Dazzed';
  src:
    url('/fonts/Dazzed-SemiBoldItalic.woff2') format('woff2'),
    url('/fonts/Dazzed-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: optional;
}

// Medium Italic
@font-face {
  font-family: 'Dazzed';
  src:
    url('/fonts/Dazzed-MediumItalic.woff2') format('woff2'),
    url('/fonts/Dazzed-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: optional;
}

.button {
  --focus-ring-color: var(--sweet-liquorice-900);
  background: none;
  border-radius: var(--radius-4-xl);
  clip-path: inset(0% 0% 0% 0% round var(--radius-4-xl));
  cursor: pointer;
  display: inline-block;
  font-family: var(--font-stack-base);
  justify-self: center;
  outline: none;
  position: relative;
  text-decoration: none;

  --bubble-duration: .4s;

  &.inversed {
    --button-disabled-bg-color: var(--sweet-liquorice-750);
    --button-disabled-color: var(--sweet-liquorice-600);

    // Primary
    --button-primary-bg-color: var(--white-000);
    --button-primary-color: var(--sweet-liquorice-900);

    // Secondary
    --button-secondary-bg-color: var(--sweet-liquorice-900);
    --button-secondary-color: var(--white-000);

    // Tertiary
    --button-tertiary-color: var(--white-000);
    --button-tertiary-hover-color: var(--white-000);
    --button-tertiary-active-color: var(--white-000);
    --button-tertiary-hover-bg-color: var(--sweet-liquorice-700);
  }

  &.disabled,
  &:disabled {
    cursor: not-allowed;
    pointer-events: none;
  }

  &:focus {
    outline: none;

    .button-content {
      &.primary,
      &.secondary {
        &::before {
          --border-width: 3px;
          border-color: var(--button-secondary-color);
        }
      }

      &.tertiary {
        outline: 1px dotted var(--focus-ring-color);
      }
    }
  }

  // Undo focus styles in browsers that support focus-visible
  &:focus:not(:focus-visible) .button-content {
    &.primary,
    &.secondary {
      &::before {
        --border-width: 1px;
        border-color: transparent;
      }
    }

    &.secondary {
      &::before {
        border-color: var(--button-secondary-color);
      }
    }

    &.tertiary {
      outline: none;
    }
  }

  // Add even cooler focus styles that support focus visible
  &:focus-visible .button-content {
    &.primary,
    &.secondary {
      background-color: var(--button-primary-hover-bg-color);
      color: var(--button-primary-hover-color);

      &::before {
        --border-width: 3px;
        border-color: var(--button-secondary-color);
      }
    }

    &.tertiary.icon-only {
      .icon {
        background-color: var(--button-tertiary-hover-bg-color);
      }
    }

    &.bubble {
      background-color: var(--button-bubble-inversed-hover-bg-color);
      color: var(--button-bubble-inversed-hover-color);

      &:hover {
        background-color: var(--button-bubble-hover-bg-color);
        color: var(--button-bubble-hover-color);
      }

      &.inversed {
        background-color: var(--button-bubble-hover-bg-color);
        color: var(--button-bubble-hover-color);
      }
    }

    &.tertiary .button-children {
      --width: 3px;
      --accent: var(--sweet-liquorice-900);

      &::before {
        opacity: 0;
      }

      &::after {
        transform-origin: left top;
        transform: scale(1, 1);
      }
    }

    &.tertiary:active .button-children {
      --width: 1px;
    }

    &.tertiary {
      outline: none;
    }
  }

  &.tertiary {
    clip-path: none;
  }
}

// Styles for the text within the button
.button-content {
  align-items: center;
  border-radius: var(--radius-4-xl);
  cursor: pointer;
  display: inline-flex;
  gap: var(--spacing-m);
  justify-content: center;
  position: relative;
  transform: translateZ(0);
  transition: none var(--slow-transition) var(--ease-out);
  transition-property: background-color, color;
  white-space: nowrap;

  span {
    z-index: 99;
    display: flex;
  }

  .icon {
    display: flex;
    position: relative;
    transition: background-color var(--regular-transition) ease;
  }

  &.l {
    @include button-l;
    gap: var(--spacing-m);
    padding: 0 32px;

    &:not(.tertiary) {
      height: var(--ui-size-l);
    }

    &.icon-only {
      padding: 16px;
    }

    .icon svg {
      height: 24px;
      width: 24px;
    }
  }

  &.m {
    @include button-m;
    padding: 0 24px;

    &:not(.tertiary) {
      height: var(--ui-size-m);
    }

    &.icon-only {
      padding: 12px;
    }

    .icon svg {
      height: 24px;
      width: 24px;
    }
  }

  &.s {
    @include button-s;
    padding: 0 16px;

    &:not(.tertiary) {
      height: var(--ui-size-s);
    }

    &.icon-only {
      padding: 8px;
    }

    .icon svg {
      height: 16px;
      width: 16px;
    }
  }

  &.icon-only {
    border-radius: 50%;

    &.full-width {
      width: auto;
    }
  }

  &.primary,
  &.secondary {
    position: relative;
    transform-origin: center;
    transition: none var(--slow-transition) var(--ease-out);
    transition-property: transform, background-color, opacity, color;

    &::before {
      --border-width: 1px;
      border-radius: var(--radius-4-xl);
      border: var(--border-width) solid transparent;
      content: '';
      height: calc(100% - var(--border-width) * 2);
      left: 0;
      pointer-events: none;
      position: absolute;
      top: 0;
      width: calc(100% - var(--border-width) * 2);
      z-index: 100;
    }
  }

  &.primary {
    background-color: var(--button-primary-bg-color);
    color: var(--button-primary-color);

    &:hover {
      background-color: var(--button-primary-hover-bg-color);
      color: var(--button-primary-hover-color);
    }

    &:active {
      background-color: var(--button-primary-active-bg-color);
      color: var(--button-primary-active-color);

      &.disabled {
        background-color: var(--button-disabled-bg-color);
        box-shadow: none;
        color: var(--button-disabled-color);
      }
    }

    &.disabled {
      background-color: var(--button-disabled-bg-color);
      color: var(--button-disabled-color);
      cursor: not-allowed;
    }

    &.negative {
      &:hover {
        // stylelint-disable-next-line stylistic/max-line-length
        background-color: var(--button-primary-hover-negative-bg-color);
        color: var(--button-primary-hover-color);
      }
    }
  }

  &.secondary {
    background-color: var(--button-secondary-bg-color);
    color: var(--button-secondary-color);

    &::before {
      border-color: var(--button-secondary-color);
    }

    &:hover {
      background-color: var(--button-secondary-hover-bg-color);
      color: var(--button-secondary-hover-color);

      &::before {
        border-color: transparent;
      }
    }

    &:active {
      background-color: var(--button-secondary-active-bg-color);
      color: var(--button-secondary-active-color);

      &.disabled {
        background-color: var(--button-disabled-bg-color);
        border-color: var(--disabled-bg-color);
        box-shadow: none;
        color: var(--button-disabled-color);
      }
    }

    &.disabled {
      background-color: var(--button-disabled-bg-color);
      border-color: var(--disabled-bg-color);
      color: var(--button-disabled-color);
      cursor: not-allowed;
    }
  }

  &.tertiary {
    border-radius: var(--radius-none);
    color: var(--button-tertiary-color);
    gap: var(--spacing-s);
    padding: 0;

    .icon {
      border-radius: var(--radius-4-xl);
    }

    .button-children {
      --accent: var(--sweet-liquorice-500);
      --width: 1px;
      --size: 0%;

      color: currentcolor;
      display: inline-block;
      line-height: var(--leading-normal);
      position: relative;
      text-decoration: none;

      &::before,
      &::after {
        border-radius: 1em;
        content: '';
        height: var(--width);
        left: 0;
        position: absolute;
        width: 100%;
      }

      &::before {
        bottom: -2px;
        transform: scale(1, 1);
      }

      &::after {
        background: var(--accent);
        bottom: -2px;
        transform-origin: right top;
        transform: scale(0, 1);
        transition: transform .3s;
      }
    }

    @media (hover: hover) {
      &:hover .button-children {
        &::before {
          opacity: 0;
        }

        &::after {
          transform-origin: left top;
          transform: scale(1, 1);
        }
      }
    }

    &:hover {
      color: var(--button-tertiary-hover-color);

      .icon {
        background-color: var(--button-tertiary-hover-bg-color);
      }
    }

    &:active {
      color: var(--button-tertiary-active-color);
    }

    &.disabled,
    &:disabled {
      color: var(--button-tertiary-disabled-color);

      .icon {
        background-color: transparent;
      }
    }

    &.l .icon {
      padding: 4px;

      svg {
        height: 24px;
        width: 24px;
      }
    }

    &.m .icon {
      padding: 6px;

      svg {
        height: 16px;
        width: 16px;
      }
    }

    &.s .icon {
      padding: 6px;

      svg {
        height: 12px;
        width: 12px;
      }
    }
  }

  &.bubble {
    --main-color: var(--white-000);

    span:not(.blowing) {
      z-index: 110;
      min-width: 24px;
    }

    &:hover {
      background-color: var(--button-bubble-hover-bg-color);
      color: var(--button-bubble-hover-color);

      .blowing {
        opacity: 0;
      }
    }

    &.inversed {
      --main-color: var(--sweet-liquorice-900);

      &:hover {
        // stylelint-disable-next-line stylistic/max-line-length
        background-color: var(--button-bubble-inversed-hover-bg-color);
        color: var(--button-bubble-inversed-hover-color);

        .blowing {
          opacity: 0;
        }
      }

      &:active {
        // stylelint-disable-next-line stylistic/max-line-length
        background-color: var(--button-bubble-inversed-hover-bg-color);
        color: var(--button-bubble-inversed-hover-color);
      }
    }

    .blowing {
      width: 80px;
      height: 80px;
      background-color: var(--main-color);
      border-radius: 50%;
      position: absolute;
      bottom: 0;
      left: 50%;
      z-index: 101;
      transition: none var(--slow-transition) var(--ease-out);
      transition-property: opacity;
    }
  }
}

// Disable styles when button is HTML disabled
// OR when aria-disabled="true"
.button:disabled,
.button[aria-disabled='true'] {
  .button-content {
    cursor: not-allowed;

    &.primary {
      background-color: var(--button-disabled-bg-color);
      color: var(--button-disabled-color);
      cursor: not-allowed;

      &:active {
        background-color: var(--button-disabled-bg-color);
        box-shadow: none;
        color: var(--button-disabled-color);
      }

      span ::before {
        border-color: transparent;
      }
    }

    &.secondary {
      background-color: var(--button-disabled-bg-color);
      border-color: var(--disabled-bg-color);
      color: var(--button-disabled-color);
      cursor: not-allowed;

      &:active {
        background-color: var(--button-disabled-bg-color);
        border-color: var(--disabled-bg-color);
        box-shadow: none;
        color: var(--button-disabled-color);
      }

      &::before {
        border-color: transparent;
      }
    }

    &.tertiary {
      color: var(--button-tertiary-disabled-color);
    }
  }

  &:focus-within .button-content.secondary::before,
  &:focus .button-content.secondary::before {
    border-color: transparent;
  }
}

.full-width {
  width: 100%;
}
