@use 'sass:math';
@use '../config/media-queries';

// GRID
[data-container-size='s'] {
  max-width: 1024px;
}

[data-container-size='m'] {
  max-width: 1280px;
}

[data-container-size='l'] {
  max-width: 1440px;
}

[data-container-size='1-xl'] {
  max-width: 1650px;
}

[data-container-size='2-xl'] {
  max-width: 1920px;
}

[data-grid] {
  --gap: 60px;

  display: grid;
  gap: var(--gap);
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-right: 32px;
  padding-left: 32px;
  max-width: 1440px;

  &[data-gap] {
    gap: var(--gap);
  }

  &[data-align] {
    place-items: var(--align);
  }

  @media screen and (min-width: media-queries.$bp-desktop-1-xl) {
    max-width: 1650px;
  }

  @media screen and (min-width: media-queries.$bp-desktop-2-xl) {
    max-width: 1920px;
  }

  @media screen and (max-width: media-queries.$bp-desktop-1-xl) {
    --gap: 56px;
  }

  @media screen and (max-width: media-queries.$bp-tablet-m) {
    --gap: 24px;
    padding-right: 24px;
    padding-left: 24px;
  }

  @media screen and (max-width: media-queries.$bp-mobile-l) {
    --gap: 16px;
    padding-right: 20px;
    padding-left: 20px;
  }

  &[data-remove-padding] {
    padding-right: 0;
    padding-left: 0;
  }

  &[data-remove-tablet-padding] {
    @media screen and (max-width: media-queries.$bp-tablet-m) {
      --gap: 24px;
      padding-right: 0;
      padding-left: 0;
    }
  }
}

@for $i from 1 through 12 {
  [data-cols='#{$i}'] {
    grid-template-columns: repeat(#{$i}, [col] 1fr);
  }

  @media screen and (max-width: media-queries.$bp-tablet-l) {
    [data-cols='#{$i}'] {
      grid-template-columns: repeat(math.ceil($i * .5), [col] 1fr);
    }
  }

  @media screen and (max-width: media-queries.$bp-mobile-l) {
    [data-cols='#{$i}'] {
      // stylelint-disable-next-line stylistic/max-line-length
      grid-template-columns: repeat(math.ceil(math.div($i, 3)), [col] 1fr);
    }
  }
}

// COL
[data-col] {
  max-width: 100%;
}

@for $i from 1 through 12 {
  [data-span='#{$i}'] {
    grid-column: span var(--span) / span var(--span);
    grid-column-start: var(--start);

    @media screen and (min-width: media-queries.$bp-desktop-1-xl) {
      grid-column: span var(--span-1-xl, var(--span)) / span var(--span-1-xl, var(--span));
      grid-column-start: var(--start);
    }
  }
}

[data-end] {
  grid-column-end: var(--end);
}

@media screen and (max-width: media-queries.$bp-desktop-s) {
  @for $i from 10 through 12 {
    [data-span='#{$i}'] {
      grid-column: 1 / -1;
    }
  }

  @for $i from 1 through 3 {
    [data-span='#{$i}'] {
      grid-column: span 6 / span 6;
    }
  }
}

@media screen and (max-width: media-queries.$bp-tablet-l) {
  @for $i from 4 through 12 {
    [data-span='#{$i}'] {
      grid-column: 1 / -1;
    }
  }

  @for $i from 1 through 3 {
    [data-span='#{$i}'] {
      grid-column: span 3 / span 3;
    }
  }
}

@media screen and (max-width: media-queries.$bp-tablet-s) {
  @for $i from 1 through 3 {
    [data-span='#{$i}'] {
      grid-column: 1 / -1;
    }
  }
}
