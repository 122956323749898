@use 'sass:color';

@function to-rgb($hex) {
  @return color.red($hex), color.green($hex), color.blue($hex);
}

$colors: (
  // Foundation
  // Fresh Mint
  --fresh-mint-100: #f9fefe,
  --fresh-mint-150: #f0ffff,
  --fresh-mint-200: #e8fefe,
  --fresh-mint-250: #ccfdfd,
  --fresh-mint-300: #b1fcfb,
  --fresh-mint-400: #93f9f8,
  --fresh-mint-500: #51f0ed,
  --fresh-mint-600: #18dcd9,
  --fresh-mint-700: #04afac,
  --fresh-mint-800: #0d8c8a,
  --fresh-mint-900: #00615f,
  --fresh-mint-950: #004241,
  // Sweet Liquorice
  --sweet-liquorice-100: #f5f7fa,
  --sweet-liquorice-200: #e7ecf0,
  --sweet-liquorice-250: #a6b2c5,
  --sweet-liquorice-300: #d2d8df,
  --sweet-liquorice-400: #abb3ba,
  --sweet-liquorice-500: #888f96,
  --sweet-liquorice-600: #6c737a,
  --sweet-liquorice-700: #41484f,
  --sweet-liquorice-750: #31373d,
  --sweet-liquorice-800: #20262c,
  --sweet-liquorice-900: #11171d,
  // Accent
  // Icy Slushie
  --icy-slushie-050: #f9fafe,
  --icy-slushie-100: #f5f7ff,
  --icy-slushie-200: #e7ecfd,
  --icy-slushie-250: #cfd9fc,
  --icy-slushie-300: #a1b6fc,
  --icy-slushie-400: #4c76ff,
  --icy-slushie-500: #295bff,
  --icy-slushie-600: #1d4add,
  --icy-slushie-700: #0f36b8,
  --icy-slushie-800: #01228e,
  --icy-slushie-900: #011760,
  // Sticky Citrus
  --sticky-citrus-050: #fffef7,
  --sticky-citrus-100: #fffced,
  --sticky-citrus-200: #fff5c2,
  --sticky-citrus-250: #ffec9c,
  --sticky-citrus-300: #ffe371,
  --sticky-citrus-400: #ffdb4a,
  --sticky-citrus-500: #ffcf10,
  --sticky-citrus-600: #eaab00,
  --sticky-citrus-700: #da9500,
  --sticky-citrus-800: #b07300,
  --sticky-citrus-900: #925200,
  // Chewy Cherry
  --chewy-cherry-050: #fff7fb,
  --chewy-cherry-100: #fceef5,
  --chewy-cherry-200: #ffd7e7,
  --chewy-cherry-250: #ffc7de,
  --chewy-cherry-300: #ffa4c1,
  --chewy-cherry-400: #ff80a8,
  --chewy-cherry-500: #f45a8b,
  --chewy-cherry-600: #e3356c,
  --chewy-cherry-700: #c12554,
  --chewy-cherry-800: #8f002b,
  --chewy-cherry-900: #780028,
  // Blueberry Bubble
  --blueberry-bubble-050: #fbf7ff,
  --blueberry-bubble-100: #f4ecff,
  --blueberry-bubble-200: #e9d7ff,
  --blueberry-bubble-250: #e1c7ff,
  --blueberry-bubble-300: #cea4ff,
  --blueberry-bubble-400: #bd80ff,
  --blueberry-bubble-500: #a45af4,
  --blueberry-bubble-600: #8c35e3,
  --blueberry-bubble-700: #7325c1,
  --blueberry-bubble-800: #570ba4,
  --blueberry-bubble-900: #3c0078,
  // Error
  --error-100: #fff7f8,
  --error-300: #ffa0ac,
  --error-500: #e8374c,
  --error-700: #720b18,
  --error-900: #410008,
  // Success
  --success-100: #fbfff7,
  --success-300: #d0ffa0,
  --success-500: #8fda44,
  --success-700: #438006,
  --success-900: #204100,
  // Misc
  --white-000: #fff,
  --black-000: #000,
  --gray-000: #777
);

:root {
  // Colors
  @each $var, $color in $colors {
    #{$var}: #{$color};
    #{$var}-rgb: #{to-rgb($color)};
  }

  /*
  USAGE:

  Regular:
  `color: var(--success-600),`

  With opacity:
  `color: rgba(var(--success-600-rgb), 0.15),`

  */
}
