@use 'media-queries' as *;

:root {
  // Spacing Tokens
  --spacing-3-xs: 0;
  --spacing-2-xs: 2px;
  --spacing-1-xs: 4px;
  --spacing-s: 6px;
  --spacing-m: 8px;
  --spacing-l: 12px;
  --spacing-1-xl: 16px;
  --spacing-2-xl: 24px;
  --spacing-3-xl: 32px;
  --spacing-4-xl: 40px;
  --spacing-5-xl: 56px;
  --spacing-6-xl: 80px;
  --spacing-7-xl: 104px;

  // Spacing Tokens tablet-m: 768px
  @media screen and (min-width: $bp-tablet-m) {
    --spacing-3-xs: 2px;
    --spacing-2-xs: 4px;
    --spacing-1-xs: 6px;
    --spacing-s: 8px;
    --spacing-m: 12px;
    --spacing-l: 16px;
    --spacing-1-xl: 24px;
    --spacing-2-xl: 32px;
    --spacing-3-xl: 40px;
    --spacing-4-xl: 56px;
    --spacing-5-xl: 72px;
    --spacing-6-xl: 96px;
    --spacing-7-xl: 128px;
  }

  // Spacing Tokens desktop-m: 1280px
  @media screen and (min-width: $bp-desktop-m) {
    --spacing-3-xs: 2px;
    --spacing-2-xs: 4px;
    --spacing-1-xs: 6px;
    --spacing-s: 8px;
    --spacing-m: 12px;
    --spacing-l: 16px;
    --spacing-1-xl: 24px;
    --spacing-2-xl: 32px;
    --spacing-3-xl: 48px;
    --spacing-4-xl: 64px;
    --spacing-5-xl: 88px;
    --spacing-6-xl: 128px;
    --spacing-7-xl: 176px;
  }

  // Spacing Tokens desktop-x: 1650px
  @media screen and (min-width: $bp-desktop-1-xl) {
    --spacing-3-xs: 2px;
    --spacing-2-xs: 4px;
    --spacing-1-xs: 8px;
    --spacing-s: 12px;
    --spacing-m: 16px;
    --spacing-l: 24px;
    --spacing-1-xl: 32px;
    --spacing-2-xl: 40px;
    --spacing-3-xl: 56px;
    --spacing-4-xl: 80px;
    --spacing-5-xl: 112px;
    --spacing-6-xl: 144px;
    --spacing-7-xl: 216px;
  }

  // UI Size Tokens
  --ui-size-4-xs: 12px;
  --ui-size-3-xs: 16px;
  --ui-size-2-xs: 20px;
  --ui-size-1-xs: 24px;
  --ui-size-s: 32px;
  --ui-size-m: 48px;
  --ui-size-l: 56px;
  --ui-size-1-xl: 64px;
  --ui-size-2-xl: 80px;
  --ui-size-3-xl: 96px;
  --ui-size-4-xl: 112px;
  --ui-size-5-xl: 152px;

  // Radius Tokens
  --radius-none: 0;
  --radius-1-xs: 2px;
  --radius-s: 4px;
  --radius-m: 6px;
  --radius-l: 8px;
  --radius-1-xl: 8px;
  --radius-2-xl: 16px;
  --radius-3-xl: 24px;
  --radius-4-xl: 32px;
  --radius-5-xl: 40px;
  --radius-full: 9999px;

  // Zoom values
  --zoom-scale: 1.07;

  // Alias Colors
  --bg-color: var(--white-000);

  // Button
  --button-disabled-bg-color: var(--sweet-liquorice-200);
  --button-disabled-color: var(--sweet-liquorice-300);

  // Primary Background Colors
  --button-primary-bg-color: var(--sweet-liquorice-900);
  --button-primary-hover-bg-color: var(--fresh-mint-500);
  --button-primary-active-bg-color: var(--fresh-mint-600);
  --button-primary-hover-negative-bg-color: var(--white-000);

  // Primary colors
  --button-primary-color: var(--white-000);
  --button-primary-hover-color: var(--sweet-liquorice-900);
  --button-primary-active-color: var(--sweet-liquorice-900);

  // Secondary Background Colors
  --button-secondary-bg-color: var(--white-000);
  --button-secondary-hover-bg-color: var(--fresh-mint-500);
  --button-secondary-active-bg-color: var(--fresh-mint-600);

  // Secondary Colors
  --button-secondary-color: var(--sweet-liquorice-900);
  --button-secondary-hover-color: var(--sweet-liquorice-900);
  --button-secondary-active-color: var(--sweet-liquorice-900);

  // Tertiary Colors
  --button-tertiary-color: var(--sweet-liquorice-900);
  --button-tertiary-hover-color: var(--sweet-liquorice-800);
  --button-tertiary-hover-bg-color: var(--fresh-mint-250);
  --button-tertiary-active-color: var(--sweet-liquorice-800);
  --button-tertiary-disabled-color: var(--sweet-liquorice-500);

  // Bubble
  --button-bubble-hover-bg-color: var(--sweet-liquorice-900);
  --button-bubble-hover-color: var(--white-000);
  --button-bubble-inversed-hover-bg-color: var(--white-000);
  --button-bubble-inversed-hover-color: var(--sweet-liquorice-900);

  // Input Background Colors
  --input-bg-color: transparent;
  --input-checked-bg-color: var(--sweet-liquorice-900);
  --input-checked-hover-bg-color: var(--sweet-liquorice-600);
  --input-disabled-bg-color: var(--sweet-liquorice-200);
  --input-disabled-checked-bg-color: var(--sweet-liquorice-200);
  --input-error-checked-bg-color: var(--chewy-cherry-700);
  --input-error-disabled-bg-color: var(--chewy-cherry-300);
  --input-error-checked-disabled-bg-color: var(--chewy-cherry-500);

  // Input Border Colors
  --input-border-color: var(--sweet-liquorice-750);
  --input-hover-border-color: var(--sweet-liquorice-800);
  --input-active-border-color: var(--sweet-liquorice-500);
  --input-checked-border-color: var(--sweet-liquorice-750);
  --input-checked-hover-border-color: var(--sweet-liquorice-200);
  --input-focus-border-color: var(--fresh-mint-600);
  --input-focus-checked-border-color: var(--bg-color);
  --input-disabled-border-color: var(--sweet-liquorice-300);
  --input-error-border-color: var(--chewy-cherry-700);

  // Input Colors
  --input-color: var(--sweet-liquorice-900);
  --input-label-color: var(--sweet-liquorice-900);
  --input-disabled-label-color: var(--sweet-liquorice-250);
  --input-placeholder-color: var(--sweet-liquorice-400);
  --input-placeholder-disabled-color: var(--sweet-liquorice-250);
  --input-caret-color: var(--fresh-mint-700);

  // Focus Ring Color
  --focus-ring-color: var(--icy-slushie-500);

  // Selection Colors
  --selection-fresh-mint-200: var(--fresh-mint-200);
  --selection-fresh-mint-300: var(--fresh-mint-300);
  --selection-fresh-mint-400: var(--fresh-mint-400);
  --selection-fresh-mint-500: var(--fresh-mint-500);
  --selection-sweet-liquorice: var(--sweet-liquorice-600);
  --selection-chewy-cherry: var(--chewy-cherry-300);
  --selection-sticky-citrus: var(--sticky-citrus-400);
  --selection-blueberry-bubble: var(--blueberry-bubble-300);
  --selection-icy-slushie: var(--icy-slushie-300);
}
