* {
  @media screen
    and (min-width: 1024px)
    and (prefers-reduced-motion: no-preference) {
    cursor: none !important; // Hide cursor on desktop
  }
}

body {
  --scrollbar-width: 0;
  --aspect-ratio: 16/9;

  font-family: var(--font-stack-base);
  background-color: var(--white-000);
  -webkit-font-smoothing: antialiased;

  // Needs to be cased like this according to CSS Spec
  /* stylelint-disable-next-line value-keyword-case */
  text-rendering: optimizeLegibility;
  color: var(--black-000);
  padding-right: var(--scrollbar-width);
  overscroll-behavior-y: none;
  max-width: 100vw;
  overflow-x: hidden;
}

::selection {
  background-color: var(--selection-fresh-mint-300);
}

.aspect-ratio {
  position: relative;
  height: 0;
  padding-bottom: calc(100% / (var(--aspect-ratio)));

  > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

blockquote,
q,
cite {
  font-style: normal;
}

hr {
  width: 100%;
  height: 1px;
  background-color: var(--sweet-liquorice-200);
  border: 0;
  margin: 0;

  &.vertical {
    width: 1px;
    height: 100%;
  }
}

/*
  This will hide the focus indicator
  if the element receives focus via the mouse,
  but it will still show up on keyboard focus.
*/

.js-focus-visible :focus:not(.focus-visible) {
  outline: 0;
}

.js-focus-visible .focus-visible {
  outline: 2px solid var(--icy-slushie-500);
  outline-offset: 2px;
}

@mixin follow-mouse {
  --duration: .3s;
  transition: transform var(--duration) var(--ease-out);
  transform: translate3d(var(--x), var(--y), 0) translate(-50%, -50%);
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}
