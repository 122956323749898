pre {
  padding: var(--spacing-3-xl);
  max-width: 904px;
  margin: auto;
  background: var(--sweet-liquorice-900);
  border-radius: var(--radius-1-xs);
  font-size: var(--body-m);

  @media screen and (max-width: 768px) {
    overflow: scroll;
  }

  code {
    padding: var(--spacing-1-xs) 0;
    line-height: var(--spacing-1-xl);
    color: var(--icy-slushie-300);
    font-weight: 400;
    font-family: 'Roboto Mono', monospace;

    span::selection,
    &::selection {
      background: var(--sweet-liquorice-750);
      color: var(--sweet-liquorice-200);
    }
  }

  &.line-numbers {
    padding-left: var(--spacing-5-xl) !important;

    @media screen and (max-width: 768px) {
      padding: var(--spacing-3-xl) var(--spacing-5-xl) !important;
    }
  }

  .line-numbers-rows {
    border-right: none !important;
    margin-top: var(--spacing-2-xs);

    > span {
      line-height: var(--spacing-1-xl);
      color: #888f96;
      border: none;
    }
  }

  .comment {
    color: #888f96;
  }

  .function {
    color: var(--chewy-cherry-400);
  }

  .operator,
  .punctuation {
    color: var(--white-000);
  }

  .parameter {
    color: #a1b6fc;
  }

  .number {
    color: #ffdb4a;
  }
}

// Generated from https://prism.dotenv.dev/
.token.comment {
  color: #3f5169;
  font-style: italic;
}

.token.punctuation {
  color: var(--white-000);
}

.token.variable {
  color: #92a7c4;
}

.token.constant {
  color: #fff;
}

.token.keyword {
  color: var(--white-000);
}

.token.string {
  color: #d5a27c;
}

.token.number {
  color: #d5a27c;
}

.token.char {
  color: #d5a27c;
}

.token.tag {
  color: #fe60a1;
}

.token.deleted {
  color: #fe60a1;
}

.token.builtin {
  color: #8bd49c;
}

.token.symbo1l {
  color: #7a6ce5;
}

.token.inserted {
  color: #7a6ce5;
}

.token.changed {
  color: #48c498;
}

.token.attr-name {
  color: #c792ea;
}

.token.regex {
  color: #08c;
}

.token.at-rule {
  color: #637b91;
}

.token.property {
  color: #637b91;
}

.token.operator {
  color: var(--sweet-liquorice-400);
}

.token.important,
.token.bold {
  font-weight: bold;
}

.token.italic {
  font-style: italic;
}
