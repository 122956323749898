// Media queries can't be used as native CSS variables (yet)
$bp-mobile-s: 320px !default;
$bp-mobile-m: 375px !default;
$bp-mobile-l: 414px !default;
$bp-tablet-s: 562px !default;
$bp-tablet-m: 768px !default;
$bp-tablet-l: 920px !default;
$bp-desktop-1-xs: 1024px !default;
$bp-desktop-s: 1120px !default;
$bp-mobile-nav: 1240px !default;
$bp-desktop-m: 1280px !default;
$bp-desktop-l: 1440px !default;
$bp-desktop-1-xl: 1650px !default;
$bp-desktop-2-xl: 1920px !default;

%heading {
  font-weight: 600;
  color: var(--sweet-liquorice-900);
  line-height: var(--leading-tight);
  letter-spacing: var(--tracking-normal);
}

%paragraph {
  font-weight: 500;
  color: var(--sweet-liquorice-750);
  line-height: var(--leading-normal);
  letter-spacing: var(--tracking-normal);
}

%quote {
  font-weight: 500;
  color: var(--sweet-liquorice-900);
  line-height: var(--leading-normal);
  letter-spacing: var(--tracking-normal);
}

%link {
  font-weight: 500;
  text-decoration: underline;
  color: var(--sweet-liquorice-900);
  line-height: var(--leading-none);
  letter-spacing: var(--tracking-normal);
}

%button {
  font-weight: 600;
  line-height: var(--leading-normal);
  letter-spacing: var(--tracking-normal);
}

.inversed {
  %heading {
    color: var(--white-000);
  }

  %paragraph,
  %quote,
  %link {
    color: var(--sweet-liquorice-300);
  }
}

@mixin h1 {
  @extend %heading;
  font-size: var(--heading-6-xl);
}

@mixin h2 {
  @extend %heading;
  font-size: var(--heading-3-xl);
}

@mixin h3 {
  @extend %heading;
  font-size: var(--heading-2-xl);
}

@mixin h4 {
  @extend %heading;
  font-size: var(--heading-1-xl);
}

@mixin h5 {
  @extend %heading;
  font-size: var(--heading-l);
}

@mixin h6 {
  @extend %heading;
  font-size: var(--heading-m);
}

@mixin streamer {
  @extend %paragraph;
  font-size: var(--streamer-size);
  font-weight: 500;
}

@mixin quote-1-xl {
  @extend %quote;
  font-size: var(--heading-2-xl);
}

@mixin quote-l {
  @extend %quote;
  font-size: var(--heading-l);
}

@mixin body-s {
  @extend %paragraph;
  font-size: var(--body-s);
}

@mixin body-m {
  @extend %paragraph;
  font-size: var(--body-m);
}

@mixin body-l {
  @extend %paragraph;
  font-size: var(--body-l);
}

@mixin body-1-xl {
  @extend %paragraph;
  font-size: var(--body-1-xl);
}

@mixin body-2-xl {
  @extend %paragraph;
  font-size: var(--body-2-xl);
}

@mixin textlink-l {
  @extend %link;
  font-weight: 600;
}

@mixin textlink-m {
  @extend %link;
}

@mixin textlink-s {
  @extend %link;
}

@mixin caption {
  font-weight: 500;
}

@mixin eyebrow {
  text-transform: uppercase;
  font-weight: 600;
  font-size: var(--heading-s);
  color: var(--sweet-liquorice-500);
}

@mixin footerlink-m {
  color: var(--sweet-liquorice-100);
}

@mixin footerlink-s {
  color: var(--sweet-liquorice-100);
}

@mixin button-l {
  @extend %button;
  font-size: var(--body-l);
}

@mixin button-m {
  @extend %button;
  font-size: var(--body-m);
}

@mixin button-s {
  @extend %button;
  font-size: var(--body-s);
}

// DEFAULT

// Heavy
@font-face {
  font-family: 'Dazzed';
  src:
    url('/fonts/Dazzed-Heavy.woff2') format('woff2'),
    url('/fonts/Dazzed-Heavy.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: optional;
}

// Bold
@font-face {
  font-family: 'Dazzed';
  src:
    url('/fonts/Dazzed-Bold.woff2') format('woff2'),
    url('/fonts/Dazzed-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: optional;
}

// Semi-Bold
@font-face {
  font-family: 'Dazzed';
  src:
    url('/fonts/Dazzed-SemiBold.woff2') format('woff2'),
    url('/fonts/Dazzed-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: optional;
}

// Medium
@font-face {
  font-family: 'Dazzed';
  src:
    url('/fonts/Dazzed-Medium.woff2') format('woff2'),
    url('/fonts/Dazzed-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: optional;
}

// ITALIC

// Heavy Italic
@font-face {
  font-family: 'Dazzed';
  src:
    url('/fonts/Dazzed-HeavyItalic.woff2') format('woff2'),
    url('/fonts/Dazzed-HeavyItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: optional;
}

// Bold Italic
@font-face {
  font-family: 'Dazzed';
  src:
    url('/fonts/Dazzed-BoldItalic.woff2') format('woff2'),
    url('/fonts/Dazzed-BoldItalic.woff') format('woff');
  font-weight: 700;
  font-style: italic;
  font-display: optional;
}

// Semi-Bold Italic
@font-face {
  font-family: 'Dazzed';
  src:
    url('/fonts/Dazzed-SemiBoldItalic.woff2') format('woff2'),
    url('/fonts/Dazzed-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: optional;
}

// Medium Italic
@font-face {
  font-family: 'Dazzed';
  src:
    url('/fonts/Dazzed-MediumItalic.woff2') format('woff2'),
    url('/fonts/Dazzed-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: optional;
}

.signup-input {
  contain: layout;
  display: inline-flex;
  flex-direction: column;
  position: relative;
  flex: 1;

  .label {
    margin-bottom: var(--spacing-m);
    margin-left: 24px;
    display: inline-flex;
    align-items: center;
    color: var(--sweet-liquorice-100);
    min-height: 24px;

    @media screen and (max-width: $bp-desktop-1-xs) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .error,
  .success {
    display: flex;
    align-items: center;
    /* stylelint-disable-next-line plugin/8-point-grid */
    margin: -3px 24px 0;
    border-radius: var(--radius-1-xs);
    padding: 12px;
    gap: 12px;
    position: relative;
    z-index: -1;

    svg {
      margin-bottom: auto;
      flex-shrink: 0;
    }
  }

  .error {
    background-color: var(--chewy-cherry-250);

    &,
    span {
      color: var(--chewy-cherry-900);
    }

    path:first-of-type {
      fill: var(--chewy-cherry-300);
    }

    path:nth-of-type(2) {
      fill: var(--chewy-cherry-400);
    }
  }

  .success {
    background-color: var(--fresh-mint-250);

    &,
    span {
      color: var(--fresh-mint-950);
    }
  }

  .label.is-filled {
    color: var(--sweet-liquorice-500);
  }

  .is-disabled {
    color: var(--sweet-liquorice-600);
  }
}

.signup-button {
  position: absolute;
  right: 12px;
}

.signup-input-container {
  position: relative;
  display: flex;
  align-items: center;
}

.signup-input-element {
  position: relative;
  background-color: var(--sweet-liquorice-900);
  border: 1px solid var(--sweet-liquorice-700);
  outline: 0;
  transition: border-color var(--fast-transition) var(--ease);
  border-radius: var(--radius-full);
  margin: 0;
  width: 100%;
  font-family: inherit;
  color: var(--white-000);
  font-size: var(--body-1-xl);

  padding-left: 24px;
  padding-right: 68px;
  line-height: 68px;

  @media screen and (max-width: $bp-desktop-1-xs) {
    line-height: 64px;
  }

  &::placeholder {
    color: var(--sweet-liquorice-500);
  }

  &:hover {
    border-color: var(--sweet-liquorice-500);
  }

  &:focus,
  &:focus-within {
    border-color: var(--sweet-liquorice-100);
    outline: 0;
    caret-color: var(--input-caret-color);
  }

  &.has-error {
    border-color: var(--chewy-cherry-500);

    &:hover:not(:disabled),
    &:focus,
    &:focus-within {
      border-color: var(--chewy-cherry-800);
    }
  }

  &:disabled {
    background-color: var(--sweet-liquorice-800);
    border-color: var(--sweet-liquorice-600);
    cursor: not-allowed;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    background-clip: text;
    -webkit-text-fill-color: #fff;
  }

  &.s {
    line-height: 52px;
    font-size: var(--body-l);
  }
}
